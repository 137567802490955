import { apiClient } from "./ApiClient";

export default function retrieveAllUsersApi(token) {
    return apiClient.get('/users/find/all');
}

export function retrieveUserByIdApi(id) {
    return apiClient.get(`/users/find/by/${id}`);
}

export function retrieveUserByUsernameApi(username) {
    return apiClient.get(`/users/find/by/username/${username}`);
}

export function retrieveUserByCellPhoneNumberApi(cellPhoneNumber) {
    return apiClient.get(`/users/find/by/cellPhoneNumber/${cellPhoneNumber}`);
}

export function retrieveUserByEmailApi(email) {
    return apiClient.get(`/users/find/by/${email}`);
}

export function deleteUserApi(id) {
    return apiClient.delete(`/users/delete/${id}`);
}

export function updateUserApi(id, userSignUp) {
    return apiClient.put(`/users/update/${id}`, userSignUp);
}

export function createUserApi(userSignUp, token) {
    return apiClient.post(`/users/create`, userSignUp);
}