import { useNavigate, useParams } from "react-router-dom"
import { createTenantApi, retrieveTenantByIdApi, updateTenantApi } from "./api/TMSRestApiService";
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import moment from "moment/moment";
import SideBarComponent from "./SideBarComponent";
import getTimeStamp, { emailValidator } from "./utils/commonUtility";

export default function TenantComponent() {

    const {username} = useParams();

    const {id} = useParams();

    const navigate = useNavigate();

    const [roomNumber, setRoomNumber] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [title, setTitle] = useState('');
    const [numberOfTenantsInUnit, setNumberOfTenantsInUnit] = useState('');
    const [tenantBehaviour, setTenantBehaviour] = useState('');
    const [cellPhoneNumber, setCellPhoneNumber] = useState('');
    const [alternativeCellPhoneNumber, setAlternativeCellPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [leaseStartDate, setLeaseStartDate] = useState('');
    const [leaseEndDate, setLeaseEndDate] = useState('');
    const [prepaidElectricityMeterNumber, setPrepaidElectricityMeterNumber] = useState('');
    const [depositPaid, setDepositPaid] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [rental, setRental] = useState('');
    const [tenantStatus, setTenantStatus] = useState('');

    useEffect(
        () => retrieveTenants(id), [id]
    )

    function retrieveTenants(id) {

        if (id != -1) {
            retrieveTenantByIdApi(id)
            .then(response => {
                console.log(response)
                setRoomNumber(response.data.roomNumber)
                setName(response.data.name)
                setSurname(response.data.surname)
                setTitle(response.data.title)
                setNumberOfTenantsInUnit(response.data.numberOfTenantsInUnit)
                setTenantBehaviour(response.data.tenantBehaviour)
                setCellPhoneNumber(response.data.cellPhoneNumber)
                setAlternativeCellPhoneNumber(response.data.alternativeCellPhoneNumber)
                setEmail(response.data.email)
                setLeaseStartDate(response.data.leaseStartDate)
                setLeaseEndDate(response.data.leaseEndDate)
                setPrepaidElectricityMeterNumber(response.data.prepaidElectricityMeterNumber)
                setDepositPaid(response.data.depositPaid)
                setPaymentDate(response.data.paymentDate)
                setRental(response.data.rental)
                setTenantStatus(response.data.tenantStatus)
            })
            .catch(error => console.log(error))
        }
        
    }

    function onSubmit(values) {
        const tenant = {
            createdAt: getTimeStamp(),
            updatedAt: getTimeStamp(),
            id: id,
            name: values.name,
            surname: values.surname,
            title: values.title,
            email: values.email,
            cellPhoneNumber: values.cellPhoneNumber,
            alternativeCellPhoneNumber: values.alternativeCellPhoneNumber,
            roomNumber: values.roomNumber,
            numberOfTenantsInUnit: values.numberOfTenantsInUnit,
            leaseStartDate: values.leaseStartDate,
            leaseEndDate: values.leaseEndDate,
            prepaidElectricityMeterNumber: values.prepaidElectricityMeterNumber,
            depositPaid: values.depositPaid,
            rental: values.rental,
            paymentDate: values.paymentDate,
            tenantBehaviour: values.tenantBehaviour,
            tenantStatus: values.tenantStatus
        };
        
        if (id == -1) {
            createTenantApi(tenant)
            .then(() => navigate(`/tenants/${username}`, {
                state: {
                    isCreateAlertVisible: true,
                    name: tenant.name,
                    surname: tenant.surname,
                    operation: "created"
                }
            }))
            .catch(error => console.log(error));
        } else {            
            updateTenantApi(id, tenant)
            .then(response => navigate(`/tenants/${username}`, {
                state: {
                    isUpdateAlertVisible: true,
                    name: tenant.name,
                    surname: tenant.surname,
                    operation: "updated"

                }
            }))
            .catch(error => console.log(error));
        }
    }

    function validate(values) {
        let errors = {}

        if (values.name.length < 2) {
            errors.name = "Enter at least 2 characters for Name!"
        }

        if (values.surname.length < 2) {
            errors.surname = "Enter at least 2 characters for Surname!"
        }

        if (values.numberOfTenantsInUnit.length > 5) {
            errors.numberOfTenantsInUnit = "Number of Tenant(s) in a Unit must be between 1 to 5 max!"
        }

        if (values.cellPhoneNumber.length !== 10) {
            errors.cellPhoneNumber = "Cellphone Number must be 10 digits long!"
        }
        if (values.alternativeCellPhoneNumber.length !== 10) {
            errors.alternativeCellPhoneNumber = "Alternative Cellphone Number must be 10 digits long!"
        }

        if (!values.email) {
            errors.email = "Email Address is Required!";
        } else if (emailValidator(values.email)) {
            errors.email = 'Invalid email address';
        }

        if (values.prepaidElectricityMeterNumber.length !== 11) {
            errors.prepaidElectricityMeterNumber = "Prepaid Electricity Meter Number must be 11 digits long!"
        }

        if (values.leaseStartDate === null || values.leaseStartDate==='' || !moment(values.leaseStartDate).isValid()) {
            errors.leaseStartDate = "Pick a Lease Agreement Start Date.";
        }
        if (values.leaseEndDate === null || values.leaseEndDate==='' || !moment(values.leaseEndDate).isValid()) {
            errors.leaseEndDate = "Pick a Lease Agreement End Date!";
        }
        if (values.paymentDate === '') {
            errors.paymentDate = "Please pick a Rent Payment Date!";
        }

        //console.log(values);
        return errors;
    }

    function backToTenantList() {
        navigate('/tenants/Mtshepiro');
    }

    return (
        <div className="container">
            <div className="row">

                <div className="col-3 col-md-2">
                    <SideBarComponent />
                </div>

                <div className="col-15 col-md-10">
                    <div className="row">
                        <h1>Tenant Information.</h1>
                    </div>
                    <div className="row m-4">
                        <span className="text-danger font-weight-bold">
                            (*) Indicates a Required Field.
                        </span>
                    </div>
                    <div className="row">
                        <Formik initialValues={ { tenantStatus, name, surname, title, roomNumber, numberOfTenantsInUnit, 
                        tenantBehaviour, cellPhoneNumber, alternativeCellPhoneNumber, email, leaseStartDate,
                        leaseEndDate, prepaidElectricityMeterNumber, depositPaid, paymentDate, rental } }
                        enableReinitialize = {true}
                        onSubmit={onSubmit}
                        validate={validate}
                        validateOnChange={false}
                        validateOnBlur={false}
                        >
                            {
                                (props) => (
                                    <Form>

                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="alert alert-danger"
                                        />

                                        <ErrorMessage
                                            name="surname"
                                            component="div"
                                            className="alert alert-danger"
                                        />

                                        <ErrorMessage
                                            name="numberOfTenantsInUnit"
                                            component="div"
                                            className="alert alert-danger"
                                        />

                                        <ErrorMessage
                                            name="cellPhoneNumber"
                                            component="div"
                                            className="alert alert-danger"
                                        />

                                        <ErrorMessage
                                            name="alternativeCellPhoneNumber"
                                            component="div"
                                            className="alert alert-danger"
                                        />

                                        <ErrorMessage
                                            name="prepaidElectricityMeterNumber"
                                            component="div"
                                            className="alert alert-danger"
                                        />

                                        <ErrorMessage
                                            name="leaseStartDate"
                                            component="div"
                                            className="alert alert-danger"
                                        />
                                        <ErrorMessage
                                            name="leaseEndDate"
                                            component="div"
                                            className="alert alert-danger"
                                        />
                                        <ErrorMessage
                                            name="paymentDate"
                                            component="div"
                                            className="alert alert-danger"
                                        />

                                        <div className="table-responsive">
                                            <table className="table table-responsive caption-top">
                                                <tbody>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="tenantStatus">Status</label></td>
                                                        <td>
                                                            <Field as="select" id="tenantStatus" className="form-control hs-input" name="tenantStatus">
                                                                <option value="0" className="text-success">Active</option>
                                                                <option value="1" className="text-danger">Inactive</option>
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="roomNumber">Room Number:</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td>
                                                            <Field as="select" id="roomNumber" className="form-control hs-input" name="roomNumber">
                                                                <option value="">Please Select...</option>
                                                                <option value="A1 - 1 Bedroom Apartment">A1 - 1 Bedroom Apartment</option>
                                                                <option value="A2 - 2 Bedroom Apartment">A2 - 2 Bedroom Apartment</option>
                                                                <option value="A3 - 1 Bedroom Apartment">A3 - 1 Bedroom Apartment</option>
                                                                <option value="A4 - 1 Bedroom Apartment">A4 - 1 Bedroom Apartment</option>
                                                                <option value="A5 - 1 Bedroom Apartment">A5 - 1 Bedroom Apartment</option>
                                                                <option value="A6 - 1 Bedroom Apartment">A6 - 1 Bedroom Apartment</option>
                                                                <option value="A7 - 1 Bedroom Apartment">A7 - 1 Bedroom Apartment</option>
                                                                <option value="A8 - 1 Bedroom Apartment">A8 - 1 Bedroom Apartment</option>
                                                                <option value="A9 - 1 Bedroom Apartment">A9 - 1 Bedroom Apartment</option>
                                                                <option value="A10 - 1 Bedroom Apartment">A10 - 1 Bedroom Apartment</option>
                                                                <option value="A11 - 2 Bedroom Apartment">A11 - 2 Bedroom Apartment</option>
                                                                <option value="B1 - 2 Bedroom Apartment">B1 - 2 Bedroom Apartment</option>
                                                                <option value="B2 - 2 Bedroom Apartment">B2 - 2 Bedroom Apartment</option>
                                                                <option value="C1 - Bachelor Apartment">C1 - Bachelor Apartment</option>
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="name">Name</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td><Field type="text" id="name" className="form-control" name="name" /></td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="surname">Surname</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td><Field type="text" id="surname" className="form-control" name="surname" /></td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="title">Title</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td>
                                                            <Field as="select" id="title" className="form-control hs-input" name="title">
                                                                <option value="">Please Select...</option>
                                                                <option value="Mr.">Mr.</option>
                                                                <option value="Miss.">Miss.</option>
                                                                <option value="Ms.">Ms.</option>
                                                                <option value="Mrs">Mrs.</option>
                                                                <option value="Professor.">Professor.</option>
                                                                <option value="Doctor">Doctor</option>
                                                                <option value="Sir">Sir</option>
                                                                <option value="Lord">Lord</option>
                                                                <option value="Prince">Prince</option>
                                                                <option value="Princess">Princess</option>
                                                                <option value="Chairperson">Chairperson</option>
                                                                <option value="Excellency">Excellency</option>
                                                                <option value="Pope">Pope</option>
                                                                <option value="Queen">Queen</option>
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="numberOfTenantsInUnit">No.: of Tenants in Unit</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td><Field type="number" id="numberOfTenantsInUnit" className="form-control" name="numberOfTenantsInUnit" /></td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="tenantBehaviour">Tenant Behaviour</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td>
                                                            <Field as="select" id="tenantBehavious" className="form-control hs-input" name="tenantBehaviour">
                                                                <option value="">Please Select...</option>
                                                                <option value="GOOD">GOOD</option>
                                                                <option value="BAD">BAD</option>
                                                                <option value="MODERATE">MODERATE</option>
                                                            </Field>
                                                        </td>    
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="cellPhoneNumber">Cellphone Number</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td><Field type="text" id="cellPhoneNumber" className="form-control" name="cellPhoneNumber" /></td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="alternativeCellPhoneNumber">Alternative Cellphone Number</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td><Field type="text" id="alternativeCellPhoneNumer" className="form-control" name="alternativeCellPhoneNumber" /></td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="email">E-mail Address</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td><Field type="email" id="email" className="form-control" name="email" /></td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="leaseStartDate">Lease Agreement Start Date</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td><Field type="date" id="leaseStartDate" className="form-control" name="leaseStartDate" /></td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="leaseEndDate">Lease Agreement End Date</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td><Field type="date" id="leaseEndDate" className="form-control" name="leaseEndDate" /></td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="prepaidElectricityMeterNumber">Prepaid Electricity Meter No.:</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td>
                                                            <Field type="text" id="prepaidElectricityMeterNumber" className="form-control" name="prepaidElectricityMeterNumber" />
                                                        </td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="depositPaid">Paid Deposit?</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td>
                                                            <Field as="select" id="depositPaid" className="form-control hs-input" name="depositPaid">
                                                                <option value="">Please Select...</option>
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="paymentDate">Payment Date</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td>
                                                            <Field as="select" id="paymentDate" className="form-control hs-input" name="paymentDate">
                                                                <option value="">Please Select...</option>
                                                                <option value="1st">1st</option>
                                                                <option value="7th">7th</option>
                                                                <option value="15th">15th</option>
                                                                <option value="25th">25th</option>
                                                                <option value="30th">30th</option>
                                                                <option value="31st">31st</option>
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                    <tr className="form-group">
                                                        <td><label htmlFor="rental">Rental</label><span className="text-danger font-weight-bold">*</span></td>
                                                        <td><Field type="text" id="rental" className="form-control" name="rental" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <button aria-label="Save Tenant" className="btn btn-success" type="submit">Save</button>
                                                        </td>
                                                        <td>
                                                            <button aria-label="Back to Tenant List" className="btn btn-primary" onClick={backToTenantList}>Back</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                </div>                
            </div>            
        </div>
    )
}