import { Link, useNavigate, useParams } from 'react-router-dom';
import getTimeStamp, { emailValidator, passwordRegExp } from './utils/commonUtility';
import retrieveAllUsersApi, { createUserApi } from './api/UserApiService';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from './security/AuthContext';
import { executeJWTAuthenticationApiService } from './api/AuthenticationApiService';

export default function RegistrationComponent() {

    let navigate = useNavigate();

    const {username} = "Mtshepiro";
    const {password} = "dummy";
    const authContext = useAuth();

    function onSubmit(values) {
    
        let status = "OPEN";        
    
        let userSignUp = {
            dateCreated: getTimeStamp(),
            firstName: values.firstName,
            lastName: values.lastName,
            username: values.username,
            email: values.email,
            cellPhoneNumber: values.cellPhoneNumber,
            password: values.password,
            status: status
        };

        console.log(userSignUp);

        authContext.login(username, password)
    
        createUserApi(userSignUp)
        .then(() => {

                    console.log("creating user from the API!");

                    authContext.logout();

                    navigate(`/sign-in`, {
                        state: {
                            isCreateAlertVisible: true,
                            operation: "created"
                        }
                    }
                )
            }
        )
        .catch(error => console.log(error))
    }

    function validate(values) {
        let errors = {};

        if (values.firstName.length < 2) {
            errors.firstName = "Enter at least 2 characters for the First Name!";
        }

        if (values.lastName.length < 2) {
            errors.lastName = "Enter at least 2 characters for the Last Name!";
        }
        
        if (!values.email) {
            errors.email = "Email Address is Required!";
        } else if (emailValidator(values.email)) {
            errors.email = 'Invalid email address';
        }

        if (values.cellPhoneNumber.length !== 10) {
            errors.cellPhoneNumber = "Cellphone Number must be 10 digits long!"
        }

        if (values.username.length < 2) {
            errors.username = "Enter at least 2 characters for your Username!";
        }

        if (values.password.length < 9) {
            errors.password = "Password length must be at least 8 characters long!";
        } else if (!passwordRegExp(values.password)) {
            errors.password = "Password must include a mix of lowercase, uppercase, and numerics!";
        }
    }

    return (
        <div className="background">
            <div className="container">
                <div className="row">
                    <div className="d-flex justify-content-center h-100 m-3">
                        <div className="register-card">
                            <div className="login-card-header whiteText mb-4">
                                <h1>Sign Up</h1>
                            </div>
                            <div className='row'>
                                <h5 className='lead whiteText'>
                                    Take this opportunity to become a member of the TLT-Properties family
                                </h5>
                            </div>
                            <div className="card-body">
                                <Formik initialValues={ { firstName:'', lastName:'', email:'', cellPhoneNumber:'', username:'', password:'' } }
                                    enableReinitialize = {true}
                                    onSubmit={onSubmit}
                                    validate={validate}
                                    validateOnChange={false}
                                >
                                    {
                                        (props) => (
                                            <Form>
                                                <ErrorMessage
                                                    name="firstName"
                                                    component="div"
                                                    className="alert alert-daner"
                                                />

                                                <ErrorMessage
                                                    name="lastName"
                                                    component="div"
                                                    className="alert alert-danger"
                                                />

                                                <ErrorMessage
                                                    name="username"
                                                    component='div'
                                                    className='alert alert-danger'
                                                />

                                                <ErrorMessage
                                                    name='email'
                                                    component='div'
                                                    className='alert alert-danger'
                                                />

                                                <ErrorMessage
                                                    name='cellPhoneNumber'
                                                    component='div'
                                                    className='alert alert-danger'
                                                />

                                                <ErrorMessage
                                                    name='password'
                                                    component='div'
                                                    className='alert alert-danger'
                                                />

                                                <div className="input-group form-group mb-4">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text m-">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                                                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <Field id="firstName" type="text" name="firstName" className="form-control" placeholder="First Name" autoComplete="off" />    
                                                </div>

                                                <div className="input-group form-group mb-4">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text m-">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <Field id="lastName" type="text" name="lastName" className="form-control" placeholder="Last Name" autoComplete="off" />    
                                                </div>

                                                <div className="input-group form-group mb-4">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text m-">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person-fill-lock" viewBox="0 0 16 16">
                                                                <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5v-1a2 2 0 0 1 .01-.2 4.49 4.49 0 0 1 1.534-3.693Q8.844 9.002 8 9c-5 0-6 3-6 4m7 0a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1"/>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <Field id="username" type="text" name="username" className="form-control" placeholder="Username" autoComplete="off" />    
                                                </div>

                                                <div className="input-group form-group mb-4">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text m-">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 16 16">
                                                                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z"/>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <Field id="email" type="text" name="email" className="form-control" placeholder="Email" autoComplete="off" />    
                                                </div>

                                                <div className="input-group form-group mb-4">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text m-">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <Field id="cellPhoneNumber" type="text" name="cellPhoneNumber" className="form-control" placeholder="Number" autoComplete="off" />    
                                                </div>
                                                
                                                <div className="input-group form-group mb-2">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-key-fill" viewBox="0 0 16 16">
                                                                <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2M2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <Field id="password" type="password" name="password" className="form-control" placeholder="Password" autoComplete="off" />
                                                </div>

                                                <div className="input-group form-group remember whiteText">
                                                    <Field id="acceptTsAndCs" type="checkbox" name="acceptTsAndCs" /> I accept the &nbsp; <Link aria-label="Terms of use" to={'/sign-up'} className='whiteText'>Terms of Use</Link> &nbsp; & &nbsp;<Link aria-label='Privacy Policy' to={'privacy-policy'} className='whiteText'>Privacy Policy</Link>
                                                </div>

                                                <div className="form-group">
                                                    <button aria-label="Sign Up" className="loginButton" type="submit" name="signUp">Sign Up</button>
                                                </div>
                                            </Form>
                                        )
                                    }
                                </Formik>
                            </div>
                            <div className="card-footer">
                                <div className="d-flex justify-content-center links">
                                    Already have an account?<Link to="/sign-in" className="no-underlined text-white btn btn-primary">Sign In</Link>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <Link to="/forgot-password" className="no-underlined btn btn-warning">Forgot your password?</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
    );
}
